import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import type { AxiosRequestConfig } from 'axios';
import { baseApiUrl } from 'const';
import localforage from 'localforage';
import type { RefreshResult, StoreToken } from 'models/auth';
import { toast } from 'react-toastify';
import { authActions } from 'store/reducers/auth';
import apiClient from 'store/reducers/auth/apiClient';
import { createErrorFactory, transformError } from 'utils/errors/errors';
import { logger } from 'utils/logger';
import { capitalize } from 'utils/shared';

type QueryFn = BaseQueryFn<
	{
		url: string;
		method?: AxiosRequestConfig['method'];
		data?: AxiosRequestConfig['data'];
		params?: AxiosRequestConfig['params'];
		headers?: AxiosRequestConfig['headers'];
	},
	unknown,
	ReturnType<typeof transformError>
>;

const getShouldPopulateAuthorizationHeader = (url: string) => !url.endsWith('sign_in');

const baseQueryFn =
	({ baseUrl }: { baseUrl: string } = { baseUrl: baseApiUrl }): QueryFn =>
	async ({ url, method, data, params, headers }) => {
		try {
			const extendedHeaders = {
				...headers,
			};

			if (getShouldPopulateAuthorizationHeader(url)) {
				const authTokens = await localforage.getItem<StoreToken>('auth-tokens');
				const { accessToken, tokenType } = authTokens || {};

				extendedHeaders['Authorization'] = `${tokenType} ${accessToken}`;
			}

			const result = await apiClient({
				url: baseUrl + url,
				method,
				data,
				params: new URLSearchParams(params ?? ''),
				headers: extendedHeaders,
			});

			return { data: result.data };
		} catch (error) {
			const exception = createErrorFactory(error);
			const transformedError = transformError(exception);

			if (transformedError.code < 400 || transformedError.code >= 500) {
				logger.error(exception.exception.toJSON());
			}

			return {
				error: transformedError,
			};
		}
	};

const mutex = new Mutex();
export const baseQuery: typeof baseQueryFn = (options) => async (params, api, extraOptions) => {
	const queryFn = baseQueryFn(options);

	await mutex.waitForUnlock();

	let result = await queryFn(params, api, extraOptions);

	if (result?.error && result?.error?.code === 401) {
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();
			try {
				const tokens = await localforage.getItem<StoreToken>('auth-tokens');

				if (!tokens) {
					api.dispatch(authActions.clearLoggedInUser());
					return;
				}
				const { refreshToken: oldRefreshToken, accessToken: oldAccessToken, tokenType } = tokens;

				const refreshResult = await queryFn(
					{
						url: '/auth/refresh',
						method: 'POST',
						data: { refresh_token: oldRefreshToken },
						headers: {
							Authorization: `${tokenType} ${oldAccessToken}`,
						},
					},
					api,
					extraOptions,
				);

				if (refreshResult?.data) {
					const data = refreshResult.data as RefreshResult;
					const { access_token: newAccessToken, refresh_token: newRefreshToken, token_type: newTokenType } = data;

					const normalizedTokenType = capitalize(newTokenType);

					await localforage.setItem<StoreToken>('auth-tokens', {
						accessToken: newAccessToken,
						refreshToken: newRefreshToken,
						tokenType: normalizedTokenType,
					});
					result = await queryFn(params, api, extraOptions);
				} else {
					if (refreshResult?.error?.code === 401) {
						toast.error('Час вашої сесії сплив! Будь ласка, авторизуйтеся.');
					}
					await localforage.removeItem('auth-tokens');
					api.dispatch(authActions.clearLoggedInUser());

					result = {
						data: undefined,
						error: refreshResult.error,
					};
				}
			} finally {
				release();
			}
		} else {
			await mutex.waitForUnlock();
			result = await queryFn(params, api, extraOptions);
		}
	}
	return result;
};
