import { useOrderColumns } from 'columns/order';
import ReservationErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/ReservationErrorAlertDialogue';
import SaveErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/SaveErrorAlertDialogue';
import Spinner from 'components/Spinner';
import type {
	ColumnDefinition,
	OnPinningModelChange,
	OnRowSelectionModelChange,
	OnVisibilityModelChange,
	PinningModel,
	RowSelectionModel,
	VisibilityModel,
} from 'components/Table/lib/table/types/table';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { Order, Suborder } from 'models/order';
import type { CatalogueService } from 'models/service';
import { useOrderNotifications } from 'pages/Order/hooks/useOrderNotifications';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import { useOrderAbility } from 'pages/Order/OrderAbility/provider';
import type { OrderControllerState, SuborderTab } from 'pages/Order/OrderController';
import { transformOrderToCreateOrderDTO, transformOrderToUpdateOrderDTO } from 'pages/Order/OrderController/lib/dto';
import { transformOrderToRootOrderTab, transformSuborderToSuborderTab } from 'pages/Order/OrderController/lib/transform';
import { deleteEntityFromSelectionModel } from 'pages/Order/OrderModule/utils';
import { getIsOnSplitViewPage } from 'pages/Order/utils';
import React from 'react';
import {
	useCreateOrderMutation,
	useGetServicesQuery,
	useSetOrderFromReserveMutation,
	useSetOrderOnReserveMutation,
	useUpdateSubOrderMutation,
} from 'store/reducers/orders/ordersSliceApi';

interface ChildrenProps<TData> {
	productColumns: ColumnDefinition<TData>;
	visibilityModel: VisibilityModel;
	pinningModel: PinningModel;
	onPinningModelChange: OnPinningModelChange;
	onVisibilityModelChange: OnVisibilityModelChange;
	visibilityModelSaveConfigKey: string;
	onOrderSaveSafe: VoidCallback;
	onOrderReservationSafe: VoidCallback;
	onAddBlankProduct: (selectionModel: RowSelectionModel, onRowSelectionModelChange: OnRowSelectionModelChange) => () => void;
}

interface SuborderPanelProps<TData> {
	children: (props: ChildrenProps<TData>) => JSX.Element;
	suborderIndex: number;
	blankProductId: string | null;
}

const SuborderPanel = <TData,>({ children, suborderIndex, blankProductId }: SuborderPanelProps<TData>) => {
	const { handleSubmit, setValue, resetRollbackSnapshot, removeBlankProduct, addBlankProduct, resetState } = useOrderOperationMethods();
	const { columns, pinningModel, setPinningModel, setVisibilityModel, visibilityModel, visibilityModelSaveConfigKey } = useOrderColumns({
		suborderIndex: suborderIndex,
		adjustSaveKey: '/split/products/' + suborderIndex,
	});

	const ability = useOrderAbility();
	const dialogue = useOrderAlertDialogue();
	const notify = useOrderNotifications();

	const [updateSuborderAsync, updateOrderRequest] = useUpdateSubOrderMutation();
	const [createOrderAsync, createOrderRequest] = useCreateOrderMutation();
	const [setOnReserveAsync, onReserveRequest] = useSetOrderOnReserveMutation();
	const [setFromReserveAsync, fromReserveRequest] = useSetOrderFromReserveMutation();
	const { data: services } = useGetServicesQuery<{ data: Record<string, CatalogueService> }>();

	const entityKey = getIsOnSplitViewPage() ? 'suborder' : 'order';
	const canChangeService = ability.can('change', `${entityKey}.${0}.services.table`);
	const canChangeProduct = ability.can('change', `${entityKey}.${0}.products.table`);
	const canUpdateParentOrderOnServer = canChangeProduct && canChangeService;

	const saveOrders = async (values: OrderControllerState) => {
		const orders = values.suborders
			.map((order) => {
				const hasProducts = Object.values(order.data.products ?? {}).length > 0;
				const hasServices = Object.values(order.data.services ?? {}).length > 0;

				if (!hasProducts && !hasServices) return null;

				const isNew = !order.data.id;
				const dto = isNew ? transformOrderToCreateOrderDTO(order.data) : transformOrderToUpdateOrderDTO(order.data);
				const skip = order.data.isReserved;

				return { isNew, dto, skip };
			})
			.filter(Boolean);

		const response = await Promise.all(
			orders.map(({ isNew, dto, skip }) => {
				if (skip) return Promise.resolve(dto);

				return isNew ? createOrderAsync(dto).unwrap() : updateSuborderAsync(dto).unwrap();
			}),
		);

		return response;
	};

	const handleOrderSave = handleSubmit(async (values: OrderControllerState) => {
		dialogue.open('saveSplitting', {
			onSubmit: async () => {
				if (canUpdateParentOrderOnServer) {
					const response = await saveOrders(values).finally(() => resetState(values.suborders));
					const [rootSuborder, ...restSuborders] = response;
					const rootOrderTab = transformOrderToRootOrderTab({ order: rootSuborder as Order, services });
					const restSubordersTabs = restSuborders.map((suborder, index) =>
						transformSuborderToSuborderTab({ suborder: suborder as Suborder, services, index }),
					);
					resetRollbackSnapshot();
					resetState([rootOrderTab, ...restSubordersTabs]);

					notify.successOrderSaveSplitting();
				} else {
					const suborders = values.suborders
						.slice(1)
						.map((order) => {
							const hasProducts = Object.values(order.data.products ?? {}).length > 0;
							const hasServices = Object.values(order.data.services ?? {}).length > 0;

							if (!hasProducts && !hasServices) return null;

							const isNew = !order.data.id;
							const dto = isNew ? transformOrderToCreateOrderDTO(order.data) : transformOrderToUpdateOrderDTO(order.data);

							return { isNew, dto };
						})
						.filter(Boolean);

					const response = await Promise.all(
						suborders.map(({ isNew, dto }) => {
							return isNew ? createOrderAsync(dto).unwrap() : updateSuborderAsync(dto).unwrap();
						}),
					).finally(() => resetState(values.suborders));

					const restSubordersTabs = response.map((suborder, index) =>
						transformSuborderToSuborderTab({ suborder: suborder as Suborder, services, index }),
					);
					resetRollbackSnapshot();
					resetState([values.suborders[0], ...restSubordersTabs]);

					notify.successOrderSaveSplitting();
				}
			},
		});
	});

	const handleOrderReservation = handleSubmit(async (values: OrderControllerState) => {
		const isReserved = values.suborders?.[suborderIndex].data.isReserved;

		if (isReserved) {
			dialogue.open('suborderCancelReservation', {
				onSubmit: async () => {
					const order = values?.suborders?.[suborderIndex] ?? {};

					const response = (await setFromReserveAsync({ id: order.data.id }).unwrap()) as { data: Suborder };
					const orderModel = transformSuborderToSuborderTab({ suborder: response.data, services, index: order.data.index });

					const newSuborder = {
						...order,
						data: {
							...orderModel.data,
							...order.data,
							status: orderModel.data.status,
							isReserved: orderModel.data.isReserved,
						},
					} as SuborderTab;

					const newSuborders = [...values.suborders];
					newSuborders.splice(suborderIndex, 1, newSuborder);
					setValue('suborders', newSuborders);

					notify.successOrderCancelReservation();
				},
				onCancel: () => dialogue.close(),
				data: suborderIndex,
			});
		} else {
			dialogue.open('suborderReservation', {
				onSubmit: async () => {
					const response = await saveOrders(values);
					const [rootSuborder, ...restSuborders] = response;
					const rootOrderTab = transformOrderToRootOrderTab({ order: rootSuborder as Order, services });
					const restSubordersTabs = restSuborders.map((suborder, index) =>
						transformSuborderToSuborderTab({ suborder: suborder as Suborder, services, index }),
					);

					const order = response[suborderIndex] as Order;
					notify.successOrderSaveSplitting();

					const reservationResponse = (await setOnReserveAsync({ id: order.id }).unwrap()) as { data: Suborder };
					const orderModel = transformSuborderToSuborderTab({
						suborder: reservationResponse.data,
						services,
						index: suborderIndex - 1,
					});

					const currentSuborder = restSubordersTabs[suborderIndex - 1];
					const newSuborder = {
						...currentSuborder,
						data: {
							...orderModel.data,
							...currentSuborder.data,
							status: orderModel.data.status,
							isReserved: Boolean(orderModel.data.isReserved),
						},
					} as SuborderTab;

					const newSuborders = [rootOrderTab, ...restSubordersTabs];
					newSuborders.splice(suborderIndex, 1, newSuborder);

					resetRollbackSnapshot();
					setValue('suborders', newSuborders);
					notify.successOrderReservation();
				},
				onCancel: () => dialogue.close(),
				data: suborderIndex,
			});
		}
	});

	const onOrderReservationSafe = useStopPropagationCallback(handleOrderReservation);
	const onOrderSaveSafe = useStopPropagationCallback(handleOrderSave);

	// add row to table with blank product
	const onAddBlankProduct = (selectionModel: RowSelectionModel, onRowSelectionModelChange: OnRowSelectionModelChange) => () => {
		if (blankProductId) {
			removeBlankProduct({ suborderIndex, targetId: blankProductId });

			const newSelectionModel = deleteEntityFromSelectionModel({
				selectionModel: selectionModel,
				candidates: [blankProductId],
			});

			onRowSelectionModelChange(newSelectionModel);
		} else {
			addBlankProduct({ suborderIndex });
		}
	};

	return (
		<>
			{children({
				productColumns: columns,
				onPinningModelChange: setPinningModel,
				onVisibilityModelChange: setVisibilityModel,
				pinningModel,
				visibilityModel,
				visibilityModelSaveConfigKey,
				onOrderSaveSafe,
				onOrderReservationSafe,
				onAddBlankProduct,
			})}

			{createOrderRequest.isLoading && <Spinner />}
			{updateOrderRequest.isLoading && <Spinner />}
			{onReserveRequest.isLoading && <Spinner />}
			{fromReserveRequest.isLoading && <Spinner />}

			{createOrderRequest.isError && <SaveErrorAlertDialogue message={createOrderRequest.error?.message} onClose={createOrderRequest.reset} />}
			{updateOrderRequest.isError && <SaveErrorAlertDialogue message={updateOrderRequest.error?.message} onClose={updateOrderRequest.reset} />}

			{onReserveRequest.isError && <ReservationErrorAlertDialogue message={onReserveRequest.error.message} onClose={onReserveRequest.reset} />}
		</>
	);
};

export default SuborderPanel;
