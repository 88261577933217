import { zodResolver } from '@hookform/resolvers/zod';
import Button from 'components/Button';
import FormField from 'components/FormComponents/FormField';
import Input from 'components/Input';
import Spacer from 'components/Spacer';
import Spinner from 'components/Spinner';
import { ROUTES_URLS } from 'const';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NavLink, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Arrow } from 'static/images/arrow-left.svg';
import { ReactComponent as KeyIcon } from 'static/images/key_icon.svg';
import { useResetPasswordMutation } from 'store/reducers/auth/authSliceApi';
import { prepareUrl } from 'utils/shared';
import { authEmail } from 'validation/fields';
import { z } from 'zod';

import styles from './style.module.css';

const RestorePasswordSchema = z.object({
	email: authEmail,
});
type FormValues = z.infer<typeof RestorePasswordSchema>;

const ForgotPasswordForm: React.FC = () => {
	const location = useLocation();
	const [forgotPasswordStep, setForgotPasswordStep] = useState<string>('1');
	const [resetPasswordAsync, { isLoading }] = useResetPasswordMutation();

	const form = useForm<FormValues>({
		resolver: zodResolver(RestorePasswordSchema),
		defaultValues: {
			email: location.state?.email || '',
		},
	});

	const onSubmit = async (values: FormValues) => {
		try {
			await resetPasswordAsync(values).unwrap();
			setForgotPasswordStep('2');
		} catch (error) {
			// @ts-ignore
			toast.error(error.message);
		}
	};

	const sentEmail = form.getValues().email;

	return (
		<FormProvider {...form}>
			{forgotPasswordStep === '1' ? (
				<form className={styles.formWrapper} onSubmit={form.handleSubmit(onSubmit)}>
					<span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<KeyIcon />

						<h1 className={'color-primary-800'}>Забули пароль?</h1>

						<p className="text-md-regular color-primary-800">Введіть всій E-mail та отримайте новий пароль</p>
					</span>

					<Spacer height={'32px'} />

					<FormField name="email" label="Email" placeholder="Ваш email" component={Input} fieldClassName={styles.inputField} />

					<Spacer height={'24px'} />

					<span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<Button text="Отримати новий пароль" type="submit" variant="default" />

						<Spacer height={'32px'} />

						<a href="/sign-in" className={`text-sm-medium color-primary-700 ${styles.backLink}`}>
							<Arrow className={styles.backArrow} style={{ stroke: '#1849A9' }} />
							Назад
						</a>
					</span>
				</form>
			) : (
				<div className={styles.formWrapper}>
					<span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<KeyIcon />

						<h1 className={'color-primary-800'}>Перевірте пошту</h1>
						<p className="text-md-regular">
							<span className="color-primary-800">Ми надіслали посилання на зміну паролю на </span>
						</p>
						<p className="text-center">{sentEmail}</p>

						<Spacer height={'24px'} />

						<NavLink to={prepareUrl(ROUTES_URLS.SIGN_IN)} className={`text-sm-medium color-primary-700 ${styles.backLink}`}>
							<Arrow className={styles.backArrow} style={{ stroke: '#1849A9' }} />
							Назад
						</NavLink>
					</span>
				</div>
			)}

			{isLoading && <Spinner />}
		</FormProvider>
	);
};

export default ForgotPasswordForm;
