import { OrderStatusSchema, TransactionEntitySchema } from 'models/order';
import { InternalPriceSchema } from 'models/price';
import { OrderProductSchema } from 'models/product';
import { OrderServiceSchema } from 'models/service';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

export const ServiceInternalModelStateSchema = OrderServiceSchema.pick({ code: true, title: true }).extend({
	id: z.string().nullish(),
	amount: z.string().or(z.number()),
	price: z.string().or(z.number()),
	service: z
		.object({
			id: z.string().optional(),
			title: z.string().optional(),
			services: z.any().array(),
			serviceType: z.string(),
		})
		.nullish(),
	category: z
		.object({
			id: z.string(),
			title: z.string(),
			prices: InternalPriceSchema.array(),
		})
		.nullish(),
	canEditManuallyPrice: z.boolean().default(false),
	isMetalCuttingService: z.boolean().default(false),
});

const ProductInternalModelStateSchema = OrderProductSchema.omit({
	id: true,
	amount: true,
	sumTax: true,
	sum: true,
	price: true,
}).extend({
	id: z.string(),
	amount: z.string(),
	sum: z.string(),
	price: z.string(),
	isBlank: z.boolean().optional().default(false),
	coefficient: z.any().nullish(),
	deviation: z.any().nullish(),
	profitability: z.any().nullish(),
});

const RequiredOptionSchema = z.object(
	{
		label: z.string(),
		value: z.string(),
	},
	{ message: 'Обовʼязкове поле' },
);

const SuborderSnapshotSchema = z.object({
	id: z.string().optional(),
	index: z.number(),
	stock: RequiredOptionSchema,
	responsible: RequiredOptionSchema,
	contract: RequiredOptionSchema,
	organization: RequiredOptionSchema,
	client: RequiredOptionSchema,
	sum: z.any().optional(),
	weight: z.number().default(0),
	volume: z.number().default(0),
	isReserved: z.boolean().default(false),
	isPaid: z.boolean().default(false),
	isWithoutPayment: z.boolean().default(false),
	products: z.record(z.string(), ProductInternalModelStateSchema),
	services: z.record(z.string(), ServiceInternalModelStateSchema),
	note: z.string().nullish(),
	createdAt: z.coerce.date(),
	number: z.string(),
	status: OrderStatusSchema,
	isSaved: z.boolean().default(false),
	parentId: z.string().nullable().optional(),
	realizations: TransactionEntitySchema.array(),
	date: z.any().optional().nullable(),
	lockedBy: z
		.object({
			id: z.number(),
			'1c_uuid': z.string(),
			name: z.string(),
		})
		.nullish(),
	lockedUntil: z.any().nullish(),
	access: z.union([z.literal('full'), z.literal('readonly')]).nullish(),
});
const SuborderTabSchema = z.object({
	tabName: z.string().optional(),
	data: SuborderSnapshotSchema,
});

export const OrderControllerStateSchema = z.object({
	suborders: SuborderTabSchema.array(),
	rollbackSnapshot: SuborderTabSchema.array().nullable().optional(),
	isDirty: z.boolean().optional().default(false),
});

export type OrderControllerState = z.infer<typeof OrderControllerStateSchema>;
export type SuborderSnapshot = z.infer<typeof SuborderSnapshotSchema>;
export type SuborderTab = z.infer<typeof SuborderTabSchema>;
export type ServiceInternalModelState = z.infer<typeof ServiceInternalModelStateSchema>;
export type ProductInternalModelState = z.infer<typeof ProductInternalModelStateSchema>;
export type DetailedTransferCandidate = ProductInternalModelState | ServiceInternalModelState;
export const useTypedOrderControllerFromContext = useFormContext<OrderControllerState>;
