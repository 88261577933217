import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from 'const';
import type { OriginalPaginatedRequestResult, PaginatedRequestResult, RequestResult } from 'models/api';
import { transformToPaginatedRequestResult } from 'models/api/transform';
import type { Option } from 'models/common/options';
import {
	CreateEmployee,
	CreateEmployeeColloquialData,
	CreateEmployeeColloquialDataSchema,
	Employee,
	EmployeeOptionSchema,
	EmployeePreview,
	EmployeePreviewSchema,
	EmployeesFiltersData,
	EmployeesFiltersDataSchema,
	OrganizationOptionSchema,
	ServerSideEmployeeOption,
	ServerSideOrganizationOption,
	SuspendEmployeeDTO,
	TransformedCreateEmployeeColloquialData,
	TransformedEmployeesFiltersData,
	UpdateEmployee,
} from 'models/employee';
import type { ChangeEmployeePasswordDTO } from 'models/employee/password';
import type { Stock } from 'models/stock';
import { StockOptionSchema } from 'models/stock';
import { usersQueryKeys } from 'services/queryKeys';
import { baseQuery } from 'store/config/base-query';
import { transformInToFormDataObject } from 'utils/api';
import { logger } from 'utils/logger';

export const usersSliceApi = createApi({
	reducerPath: 'users',
	baseQuery: baseQuery(),
	tagTypes: [usersQueryKeys.users()],
	endpoints: (builder) => ({
		getEmployees: builder.query<PaginatedRequestResult<EmployeePreview[]>, string>({
			query: (queryParams) => ({ url: API_ENDPOINT.allEmployees(), method: 'GET', params: queryParams }),
			transformResponse: (response: OriginalPaginatedRequestResult<EmployeePreview[]>) => {
				const result = transformToPaginatedRequestResult<EmployeePreview[]>(response);
				const validation = EmployeePreviewSchema.array().safeParse(result.data);
				const data = {
					data: validation.data,
					page: result.page,
					pagesCount: result.pagesCount,
				};

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'usersSliceApi.getEmployees' });

					data.data = result.data;
				}

				return data;
			},
			providesTags: (result) =>
				result?.data ? [usersQueryKeys.users(), usersQueryKeys.usersOnly(), ...result.data.map(({ id }) => usersQueryKeys.user(id))] : [],
		}),
		getCreateEmployeeData: builder.query<TransformedCreateEmployeeColloquialData, void>({
			query: () => ({ url: API_ENDPOINT.createEmployeeData(), method: 'GET' }),
			transformResponse: (response: CreateEmployeeColloquialData) => {
				const validation = CreateEmployeeColloquialDataSchema.safeParse(response);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'usersSliceApi.getCreateEmployeeData' });
				}
				const source = validation.success ? validation.data : response;
				const { departments, positions, users } = source;

				return {
					departments: departments.map((item) => ({ label: item.title, value: item.id })),
					positions: positions.map((item) => ({ label: item.title, value: item.id })),
					users: users.map((item) => ({ label: item.name, value: item['1c_uuid'] ?? '' })),
				};
			},
		}),
		getEmployeesFilters: builder.query<TransformedEmployeesFiltersData, void>({
			query: () => ({ url: API_ENDPOINT.employeesFilters(), method: 'GET' }),
			transformResponse: (data: EmployeesFiltersData) => {
				const validation = EmployeesFiltersDataSchema.safeParse(data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'usersSliceApi.getEmployeesFilters' });
				}
				const source = validation.success ? validation.data : data;
				const { roles } = source;

				return {
					roles: roles.map((item) => ({ label: item.title, value: item.id })),
				};
			},
		}),
		getEmployeeById: builder.query<Employee, string>({
			query: (id) => ({ url: API_ENDPOINT.employeeById(id), method: 'GET' }),
			transformResponse: (response: RequestResult<Employee>) => response.data,
			providesTags: (_, __, id) => [usersQueryKeys.user(id)],
		}),
		createEmployee: builder.mutation<Employee, CreateEmployee>({
			query: (dto) => ({ url: API_ENDPOINT.allEmployees(), method: 'POST', data: transformInToFormDataObject(dto) }),
			invalidatesTags: [usersQueryKeys.usersOnly()],
		}),
		updateEmployee: builder.mutation<Employee, UpdateEmployee>({
			query: (dto) => ({ url: API_ENDPOINT.employeeById(dto.id), method: 'PATCH', data: transformInToFormDataObject(dto) }),
			invalidatesTags: [usersQueryKeys.usersOnly()],
		}),
		getWarehouses: builder.query<Option[], void>({
			query: () => ({ url: API_ENDPOINT.stocks(), method: 'GET' }),
			transformResponse: (response: Stock[]) => {
				const validation = StockOptionSchema.array().safeParse(response);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'usersSliceApi.getWarehouses' });

					return response.map((item) => ({ label: item.title, value: item.id }));
				}

				return validation.data as Option[];
			},
		}),
		getEmployeesOptionList: builder.query<Option[], void>({
			query: () => ({ url: API_ENDPOINT.employeeOptions(), method: 'GET' }),
			transformResponse: (response: RequestResult<ServerSideEmployeeOption[]>) => {
				const validation = EmployeeOptionSchema.array().safeParse(response.data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'usersSliceApi.getEmployeesOptionList' });

					return response.data.map((item) => ({ label: item.name, value: item?.['1c_uuid'] ?? '' }));
				}

				return validation.data as Option[];
			},
		}),
		getOrganizationsOptionList: builder.query<Option[], void>({
			query: () => ({ url: API_ENDPOINT.organizations(), method: 'GET' }),
			transformResponse: (response: RequestResult<ServerSideOrganizationOption[]>) => {
				const validation = OrganizationOptionSchema.array().safeParse(response.data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'usersSliceApi.getOrganizationsOptionList' });

					return response?.data?.map((item) => ({ label: item.name, value: item?.['1c_uuid'] ?? '' }));
				}

				return validation.data as Option[];
			},
		}),
		suspendEmployee: builder.mutation<Employee, SuspendEmployeeDTO>({
			query: (dto) => ({ url: API_ENDPOINT.suspendEmployee(dto.id), method: 'PUT', data: { active: dto.active } }),
			async onQueryStarted(dto, { dispatch, queryFulfilled }) {
				const optimisticOrderUpdate = dispatch(
					usersSliceApi.util.updateQueryData('getEmployeeById', String(dto.id), (draft) => {
						Object.assign(draft ?? {}, { isActive: Number(dto.active) });
					}),
				);
				try {
					await queryFulfilled;
				} catch {
					optimisticOrderUpdate.undo();
				}
			},
		}),
		changeEmployeePassword: builder.mutation<Employee, ChangeEmployeePasswordDTO>({
			query: (dto) => ({ url: API_ENDPOINT.changeEmployeePassword(), method: 'POST', data: dto }),
			invalidatesTags: [usersQueryKeys.usersOnly()],
		}),
	}),
});

export const {
	useGetEmployeeByIdQuery,
	useGetEmployeesQuery,
	useUpdateEmployeeMutation,
	useGetWarehousesQuery,
	useCreateEmployeeMutation,
	useGetEmployeesOptionListQuery,
	useGetOrganizationsOptionListQuery,
	useGetCreateEmployeeDataQuery,
	useSuspendEmployeeMutation,
	useChangeEmployeePasswordMutation,
	useGetEmployeesFiltersQuery,
} = usersSliceApi;
