import clsx from 'clsx';
import { useAppMode } from 'hooks/useAppMode';
import { useBoolean } from 'hooks/useBoolean';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { ReactComponent as OfflineModeIcon } from 'static/images/wifi-off.svg';
import { ReactComponent as OnlineModeIcon } from 'static/images/wifi-on.svg';
import { hasTouchScreen } from 'utils/devices';

import styles from './styles.module.css';

interface ModeIndicator {
	className?: string;
}

const ModeIndicator: React.FC<ModeIndicator> = ({ className }) => {
	const tooltip = useBoolean(false);

	const { isOnline, isOffline, isNetworkConnection } = useAppMode();
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom' });

	const icon = isOnline ? <OnlineModeIcon /> : <OfflineModeIcon />;
	const networkStatusText = isNetworkConnection ? 'Так' : 'Ні';

	return (
		<>
			<div
				onTouchStart={(e) => {
					e.stopPropagation();
					tooltip.open();
					return false;
				}}
				onTouchEnd={tooltip.close}
				onTouchCancel={tooltip.close}
				onContextMenu={(e) => {
					e.preventDefault();
				}}
				ref={setReferenceElement}
				{...(!hasTouchScreen() && { onMouseEnter: () => tooltip.open(), onMouseLeave: () => tooltip.close() })}
				className={clsx(styles.modeIndicator, { [styles.offline]: isOffline, [styles.online]: isOnline }, className)}
			>
				{icon}
			</div>

			{tooltip.isOn &&
				createPortal(
					<div ref={setPopperElement} style={popperStyles.popper} {...attributes.popper} className={clsx('tooltip', styles.noArrowPointer)}>
						<div>
							<span>Поточний режим:</span> <span className={styles.semibold}>{isOnline ? 'Онлайн' : 'Офлайн'}</span>
						</div>
						<div>
							<span>Інтернет-зʼєднання:</span> <span className={styles.semibold}>{networkStatusText}</span>
						</div>
					</div>,
					document.querySelector('#portal'),
				)}
		</>
	);
};

export default ModeIndicator;
