import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from 'const';
import {
	CanProceedDangerous,
	GetCanProceedDangerousDTO,
	HasUserSchema,
	LoginDTO,
	LoginResult,
	LoginViaInvitationLinkDTO,
	RestorePasswordDTO,
	SendInvitationDTO,
	Whoami,
} from 'models/auth';
import { baseQuery } from 'store/config/base-query';

export const authSliceApi = createApi({
	reducerPath: 'authApi',
	baseQuery: baseQuery(),
	endpoints: (builder) => ({
		login: builder.mutation<LoginResult, LoginDTO>({
			query: (credentials) => ({ url: API_ENDPOINT.signIn(), method: 'POST', data: credentials }),
			transformErrorResponse: (error) => {
				if (error.code === 403) {
					return {
						code: 403,
						message: error.message,
					};
				}
				if (error.code === 401) {
					return {
						code: 401,
						message: 'Неправильний email або пароль',
					};
				}

				return {
					message: 'Щось пішло не за планом. Будь ласка, спробуйте ще раз.',
				};
			},
		}),
		getMe: builder.query<Whoami, void>({
			query: () => ({ url: API_ENDPOINT.whoami(), method: 'GET' }),
			transformResponse: (response) => {
				const validation = HasUserSchema.safeParse(response);

				if (validation.success) {
					return response;
				}

				return null;
			},
			transformErrorResponse: (error) => {
				if (error.code === 401 || error.code === 403) {
					return null;
				}

				return {
					message: error.message,
				};
			},
		}),
		logout: builder.mutation<void, void>({
			query: () => ({ url: API_ENDPOINT.signOut(), method: 'POST' }),
		}),
		resetPassword: builder.mutation<void, Pick<LoginDTO, 'email'>>({
			query: (dto) => ({ url: API_ENDPOINT.resetPassword(), method: 'POST', data: dto }),
		}),
		restorePassword: builder.mutation<void, RestorePasswordDTO>({
			query: (dto) => ({ url: API_ENDPOINT.restorePassword(), method: 'POST', data: dto }),
			transformResponse: () => undefined,
		}),
		registerViaInvitationLink: builder.mutation<void, LoginViaInvitationLinkDTO>({
			query: (dto) => ({ url: API_ENDPOINT.restorePassword(), method: 'POST', data: dto }),
			transformResponse: () => undefined,
		}),
		sendInvitation: builder.mutation<void, SendInvitationDTO>({
			query: (dto) => ({ url: API_ENDPOINT.resetPassword(), method: 'POST', data: dto }),
		}),
		getCanProceedDangerous: builder.mutation<CanProceedDangerous, GetCanProceedDangerousDTO>({
			query: (dto) => ({ url: '/auth/confirm-action', method: 'POST', data: dto }),
			transformErrorResponse: () => ({ isGranted: false }),
		}),
	}),
});

export const {
	useLoginMutation,
	useGetMeQuery,
	useLogoutMutation,
	useLazyGetMeQuery,
	useSendInvitationMutation,
	useResetPasswordMutation,
	useRestorePasswordMutation,
	useRegisterViaInvitationLinkMutation,
	useGetCanProceedDangerousMutation,
} = authSliceApi;
