import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';

type HeadingTagNames = Extract<keyof JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;
type ParagraphTagName = Extract<keyof JSX.IntrinsicElements, 'p'>;
type WrappersTagNames = Extract<keyof JSX.IntrinsicElements, 'div' | 'span' | 'strong' | 'small' | 'li' | 'label'>;
type CTATagNames = Extract<keyof JSX.IntrinsicElements, 'button' | 'a'>;

type AllowedTags = HeadingTagNames | ParagraphTagName | WrappersTagNames | CTATagNames;
interface ClampProps extends HTMLAttributes<HTMLElement> {
	as: AllowedTags;
	lines: number;
	pt?: string;
	pb?: string;
	pl?: string;
	pr?: string;
}

const Clamp: React.FC<ClampProps> = ({ as: Tag = 'p', lines = 1, children, style, className, pb, pl, pt, pr, ...rest }: ClampProps) => {
	return (
		<Tag
			className={clsx('line-clamp', className)}
			style={{ ...style, paddingBottom: pb, paddingRight: pr, paddingLeft: pl, paddingTop: pt, WebkitLineClamp: lines }}
			{...rest}
		>
			{children}
		</Tag>
	);
};

export default Clamp;
