import { FilterSearchParamsKey } from 'components/OrderCreatePageComponents/ProductFilter/lib/const';
import type { CatalogueProduct } from 'models/product/catalogue-product';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

type WatchForKey = Extract<keyof CatalogueProduct, 'leftovers' | 'freeLeftovers' | 'reserves'>;

interface SelectProductAvailabilityControllerProps {
	entity: CatalogueProduct;
	watchKey: WatchForKey;
}

const SelectProductAvailabilityController: React.FC<SelectProductAvailabilityControllerProps> = ({ entity, watchKey }) => {
	const [searchParams] = useSearchParams();

	const stockId = searchParams.get(FilterSearchParamsKey.InStock) || searchParams.get(FilterSearchParamsKey.Stock);

	const repository = entity?.[watchKey] ?? [];
	const value = repository?.find((data) => data?.stock?.id === stockId)?.amount;

	return <>{value ?? '—'}</>;
};

export default SelectProductAvailabilityController;
