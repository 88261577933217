import clsx from 'clsx';
import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import AlertDialogueMessage from 'components/AlertDialogue/components/AlertDialogueMessage';
import ModeSwitch from 'components/ModeSwitch';
import { useAppDispatch } from 'hooks/redux';
import { useAppMode } from 'hooks/useAppMode';
import { useBoolean } from 'hooks/useBoolean';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React, { useEffect } from 'react';
import { setNetworkConnection } from 'store/reducers/app';
import { checkOnlineStatus } from 'utils/shared';

import styles from './styles.module.css';

const NetworkConnection: React.FC = () => {
	const dispatch = useAppDispatch();
	const app = useAppMode();
	const offlineAlert = useBoolean();
	const onlineAlert = useBoolean();
	const switchMode = useBoolean();

	const onOfflineClose = useStopPropagationCallback(offlineAlert.close);
	const onOnlineClose = useStopPropagationCallback(onlineAlert.close);
	const onSwitchModeOpen = useStopPropagationCallback(() => {
		offlineAlert.close();
		onlineAlert.close();
		switchMode.open();
	});
	const onSwitchModeClose = useStopPropagationCallback(switchMode.close);

	useEffect(() => {
		const onOnline = () => {
			if (app.mode !== 'online') {
				offlineAlert.close();
				onlineAlert.open();
				dispatch(setNetworkConnection(true));
			}
		};
		const onOffline = () => {
			if (app.mode !== 'offline') {
				onlineAlert.close();
				offlineAlert.open();
				dispatch(setNetworkConnection(false));
			}
		};

		window.addEventListener('offline', onOffline);
		window.addEventListener('online', onOnline);
	}, [app.mode]);

	useEffect(() => {
		(async () => {
			const hasNetworkConnection = await checkOnlineStatus();
			dispatch(setNetworkConnection(hasNetworkConnection));
		})();
	}, []);

	return (
		<>
			{offlineAlert.isOn && <NetworkConnectionUnavailableAlert onSubmit={onSwitchModeOpen} onClose={onOfflineClose} />}
			{onlineAlert.isOn && <NetworkConnectionAvailableAlert onSubmit={onSwitchModeOpen} onClose={onOnlineClose} />}

			{switchMode.isOn && <ModeSwitch asModal open onClose={onSwitchModeClose} />}
		</>
	);
};

export default NetworkConnection;

interface NetworkConnectionAlertProps {
	onClose: VoidCallback;
	onSubmit: VoidCallback;
}

function NetworkConnectionUnavailableAlert({ onClose, onSubmit }: NetworkConnectionAlertProps) {
	return (
		<AlertDialogue onOutsideClick={onClose}>
			<AlertDialogueHeader onClose={onClose}>Проблеми з інтернет зʼєднанням</AlertDialogueHeader>
			<AlertDialogueContent>
				<AlertDialogueMessage className={styles.title}>
					Здається у вас зник інтернет <span className={styles.error}>:(</span>
				</AlertDialogueMessage>
				<AlertDialogueMessage>Але ви можете перевести додаток у офлайн-режим.</AlertDialogueMessage>
				<AlertDialogueMessage>
					В цьому режимі додаток працює як зазвчивай. Проте створені в офлайні заявки та/або картки клієнтів треба завантажувати на сервер,
					коли ви вийдете з офлайн-режима.
				</AlertDialogueMessage>
			</AlertDialogueContent>
			<AlertDialogueFooter>
				<AlertDialogueControlButton variant="cancel" onClick={onClose}>
					Скасувати
				</AlertDialogueControlButton>
				<AlertDialogueControlButton variant="submit" onClick={onSubmit}>
					Перейти в офлайн-режим
				</AlertDialogueControlButton>
			</AlertDialogueFooter>
		</AlertDialogue>
	);
}
function NetworkConnectionAvailableAlert({ onClose, onSubmit }: NetworkConnectionAlertProps) {
	return (
		<AlertDialogue onOutsideClick={onClose}>
			<AlertDialogueHeader onClose={onClose}>Інтернет зʼєднання відновлено</AlertDialogueHeader>
			<AlertDialogueContent>
				<AlertDialogueMessage className={styles.title}>
					Здається у вас знову є інтернет <span className={styles.success}>:)</span>
				</AlertDialogueMessage>
				<AlertDialogueMessage>Ви можете перевести додаток у онлайн-режим.</AlertDialogueMessage>
				<AlertDialogueMessage>
					Якщо ви створювали заявки та/або картки клієнтів, їх одразу можна буде завантажити на сервер або зробити це пізніше.
				</AlertDialogueMessage>
				<AlertDialogueMessage>
					Створені в офлайні сутності буду підсвічені таким <span className={clsx(styles.highlight, 'offline-entity')}>кольором</span>
				</AlertDialogueMessage>
			</AlertDialogueContent>
			<AlertDialogueFooter>
				<AlertDialogueControlButton variant="cancel" onClick={onClose}>
					Скасувати
				</AlertDialogueControlButton>
				<AlertDialogueControlButton variant="submit" onClick={onSubmit}>
					Перейти в онлайн-режим
				</AlertDialogueControlButton>
			</AlertDialogueFooter>
		</AlertDialogue>
	);
}
