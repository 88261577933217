import type { AppDispatch } from 'store';
import { clientsSliceApi } from 'store/reducers/clients/clientsSliceApi';
import { filterTemplatesSliceApi } from 'store/reducers/filterTempates/filterTemplatesSliceApi';
import { ordersSliceApi } from 'store/reducers/orders/ordersSliceApi';

const apiList = [ordersSliceApi, clientsSliceApi, filterTemplatesSliceApi];

export const resetGlobalApiState = (dispatch: AppDispatch) => {
	apiList.forEach((api) => {
		dispatch(api.util.resetApiState());
	});
};
