import clsx from 'clsx';
import Button from 'components/Button';
import Drawer from 'components/Drawer';
import MainSearch from 'components/MainSearch';
import { MainSearchAutocomplete } from 'components/MainSearch/components/MainSearchAutocomplete';
import { VoiceSearchButton } from 'components/MainSearch/components/VoiceSearchButton';
import Modal from 'components/Modal';
import ModalBody from 'components/Modal/ModalBody';
import ModalHeader from 'components/Modal/ModalHeader';
import Textarea from 'components/Textarea';
import { breakPoints } from 'const';
import { useBoolean } from 'hooks/useBoolean';
import { useOrderRouter } from 'hooks/useOrderRouter';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import BottomPanel from 'layouts/PageLayout/BottomPanel';
import { CatalogueProduct, CatalogueProductWithAmount } from 'models/product/catalogue-product';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';
import { ReactComponent as PlusBlue } from 'static/images/plus-blue.svg';
import { useGetCatalogueProductsQuery } from 'store/reducers/orders/ordersSliceApi';

import OrderCommentAlertDialogue from '../OrderCommentAlertDialogue';
import styles from './styles.module.css';
import type { IProps } from './types';

const ROOT_SUBORDER_INDEX = 0;
const commentAccessKey = `suborders.${ROOT_SUBORDER_INDEX}.data.comment` as `suborders.${number}.data.note`;

const useGetGoodsAsOptions = (queryArg: string[]) => {
	const [query = '', queryKey = ''] = queryArg;
	const search = `${queryKey}=${query}`;

	const { data: { data: products } = {}, isFetching, isLoading } = useGetCatalogueProductsQuery(search, { skip: !query || !queryKey });

	return {
		data: products,
		isFetching,
		isLoading,
	};
};

const transformQuery = (item: CatalogueProduct) => {
	return { value: item.id, label: item.title, payload: item };
};

const EmptyOrder: React.FC<IProps> = ({ className }) => {
	const { addEntityToOrder, setDirtyValue, getValues } = useOrderOperationMethods();
	const [comment, setComment] = useState(() => getValues(commentAccessKey));

	const commentInterface = useBoolean();
	const popupInterface = useBoolean();
	const router = useOrderRouter();

	const commentInterfaceClose = useStopPropagationCallback<HTMLButtonElement>(commentInterface.close);
	const popupInterfaceToggle = useStopPropagationCallback(popupInterface.toggle);

	const isMobile = useMediaQuery({ maxWidth: breakPoints.MOBILE - 1 });

	const handleAddProductClick = () => {
		router.toAddProducts();
	};

	const onProductAdd = (item: { payload: CatalogueProduct }) => {
		const candidate = { ...item.payload, amount: '1' } as CatalogueProductWithAmount;
		addEntityToOrder({ candidates: [candidate], entityName: 'products', to: 0 });
	};

	const onAddComment = useStopPropagationCallback(() => {
		setDirtyValue(commentAccessKey, comment);
		commentInterface.open();
	});

	const popupContent = (
		<>
			<Textarea
				name="note"
				{...(!isMobile && { label: 'Додати коментар' })}
				placeholder="Додайте будь-який коментар..."
				className={clsx(styles.textarea, { [styles.small]: isMobile })}
				setValue={setComment}
				value={comment}
			/>

			<div className={styles.controlsWrapper}>
				<Button icon={<PlusIcon />} variant="default" text="Додати коментар" onClick={onAddComment} />
			</div>
		</>
	);

	return (
		<div className={clsx(styles.wrapper, className)}>
			<MainSearch>
				<VoiceSearchButton />
				<MainSearchAutocomplete
					queryKey="search"
					useLoadAsyncDataQuery={useGetGoodsAsOptions}
					placeholder="Знайти товар..."
					onSelect={onProductAdd}
					transformQueryResult={transformQuery}
				/>
			</MainSearch>

			<div className={styles.subWrapper}>
				<p className={clsx('text-md-semibold', styles.addFirstProduct, styles.empty)}>Заявка порожня...</p>
				<p className={clsx(styles.addFirstProduct, styles.empty)}>Додайте перший товар до списку</p>

				<div className={styles.controlsWrapper}>
					<MediaQuery minWidth={breakPoints.MOBILE - 1}>
						<Button
							text="Додати коментар"
							onClick={popupInterfaceToggle}
							icon={<PlusBlue />}
							variant="rounded"
							background="#ffffff"
							hoverBg="#000"
							className={styles.inverseButton}
						/>
						<Button
							text="Додати товар"
							onClick={handleAddProductClick}
							icon={<PlusIcon />}
							variant="rounded"
							background="#2E90FA"
							hoverBg="#000"
							className={styles.addProductButton}
							disableClassName={styles.disableBtn}
						/>
					</MediaQuery>

					<MediaQuery minWidth={breakPoints.MOBILE}>
						{createPortal(
							<Drawer open={popupInterface.isOn} title="Додати" onClose={popupInterfaceToggle}>
								{popupContent}
							</Drawer>,
							document.querySelector('#portal'),
						)}
					</MediaQuery>

					<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
						<Modal open={popupInterface.isOn} onClose={popupInterfaceToggle} className={styles.modalOverlay}>
							<ModalHeader title="Додати коментар" onXCloseClick={popupInterfaceToggle} />
							<ModalBody className={clsx(styles.modalBody, 'hide-scroll-bar')}>{popupContent}</ModalBody>
						</Modal>
					</MediaQuery>
				</div>

				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<BottomPanel className={styles.bottomPanel}>
						<Button
							text="Додати коментар"
							onClick={popupInterfaceToggle}
							icon={<PlusIcon />}
							variant="default"
							background="#ffffff"
							hoverBg="#000"
						/>
						<Button
							text="Додати товар"
							onClick={handleAddProductClick}
							icon={<PlusIcon />}
							variant="default"
							background="red"
							hoverBg="#000"
							className={styles.bottomPanelAddProductButton}
						/>
					</BottomPanel>
				</MediaQuery>
			</div>

			{commentInterface.isOn && <OrderCommentAlertDialogue onClose={commentInterfaceClose} />}
		</div>
	);
};

export default EmptyOrder;
