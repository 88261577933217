import clsx from 'clsx';
import Input from 'components/Input';
import type { MainSearchSwitchInputProps } from 'components/MainSearch/lib/types';
import { useBoolean } from 'hooks/useBoolean';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React, { Children, cloneElement, forwardRef, isValidElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Magnify from 'static/images/Magnify.svg';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';

import styles from './styles.module.css';

interface CloneElementProps {
	onClick: (e?: React.MouseEvent<HTMLElement>) => void;
	isActive: boolean;
}

const InputComponent = (
	{ placeholder, className, children, defaultQueryKey, icon = Magnify, renderPlaceholder, ...restProps }: MainSearchSwitchInputProps,
	ref: React.ForwardedRef<HTMLInputElement>,
) => {
	const childrenArr = Children.toArray(children);
	const clearButton = useBoolean();
	const [query, setQuery] = useState('');
	const isMount = useRef(false);

	const [allPossibleQueryKeys] = useState(() => {
		return childrenArr.map((child) => isValidElement(child) && child.props?.queryKey).filter(Boolean);
	});

	const [queryKey, setQueryKey] = useState(defaultQueryKey);
	const [searchParams, setSearchParams] = useSearchParams();
	const resolvedPlaceholder = renderPlaceholder ? renderPlaceholder(queryKey) : placeholder;

	useLayoutEffect(() => {
		allPossibleQueryKeys.forEach((key) => {
			if (searchParams.has(key)) {
				setQuery(searchParams.get(key) ?? '');
				setQueryKey(key);
				clearButton.open();
			}
		});
	}, []);

	useEffect(() => {
		if (!isMount.current) {
			isMount.current = true;
			return;
		}

		if (isMount.current && !searchParams.has(queryKey) && query) {
			setQuery('');
			clearButton.close();
		}
	}, [searchParams.toString()]);

	const onClick = (key: string) => () => {
		if (key === queryKey) return;

		searchParams.delete(queryKey);
		setQueryKey(key);
		setQuery('');
		setSearchParams(searchParams);
	};

	const onClearButtonClick = () => {
		searchParams.delete(queryKey);
		searchParams.delete('page');
		setQuery('');
		setSearchParams(searchParams);
	};

	const onQueryChange = useCallback(
		(newQuery: string) => {
			const newSearchParams = new URLSearchParams(window.location.search);

			if (newQuery) {
				newSearchParams.set(queryKey, newQuery);
			} else {
				newSearchParams.delete(queryKey);
			}

			if (newSearchParams.has('page')) {
				newSearchParams.delete('page');
			}

			setSearchParams(newSearchParams);
		},
		[queryKey],
	);

	const onQueryChangeDebounced = useDebouncedCallback(onQueryChange, 400);
	const handleInputChange = (newValue: string) => {
		setQuery(newValue.trim());
		onQueryChangeDebounced(newValue.trim());
	};
	const handleInputClick = useStopPropagationCallback();
	const handleInputFocus = () => {
		if (clearButton.isOn) return;

		clearButton.open();
	};
	const handleInputBlur = () => {
		if (query) return;

		clearButton.close();
	};

	const clonedChildren = useMemo(() => {
		return childrenArr.map((child) => {
			if (!isValidElement(child)) return null;

			const copied = cloneElement(child as React.ReactElement<CloneElementProps>, {
				onClick: onClick(child.props?.queryKey ?? ''),
				isActive: queryKey === child.props?.queryKey,
			});
			return copied;
		});
	}, [children, queryKey, searchParams]);

	return (
		<>
			<Input
				ref={ref}
				type="text"
				icon={icon}
				iconPosition="leading"
				wrapperClassName={clsx(styles.wrapper, className)}
				className={clsx(styles.input, className)}
				placeholder={resolvedPlaceholder}
				autoComplete="off"
				value={query}
				onClick={handleInputClick}
				onFocus={handleInputFocus}
				onBlur={handleInputBlur}
				setValue={handleInputChange}
				{...restProps}
			/>
			<div className={styles.buttons}>
				<button
					type="button"
					onClick={onClearButtonClick}
					disabled={!query || !clearButton.isOn}
					className={clsx(styles.clear, { [styles.visible]: clearButton.isOn && query })}
				>
					<XCloseIcon />
				</button>
				{clonedChildren}
			</div>
		</>
	);
};

export const MainSearchSwitchInput = forwardRef(InputComponent) as (
	props: MainSearchSwitchInputProps & { ref?: React.ForwardedRef<HTMLInputElement> },
) => JSX.Element;
