import { isAxiosError } from 'axios';
import {
	BadRequestError,
	ChunkLoadError,
	ForbiddenError,
	InternalServerError,
	NetworkError,
	NotFoundError,
	OneCError,
	RangeError,
	ReferenceError,
	TooManyRequestsError,
	TypeError,
	UnauthorizedError,
	UnknownError,
	ZodError,
} from 'models/error';

import {
	getCodeRelatedError,
	isApiError,
	isAxiosErrorWithMessagesArray,
	isAxiosNetworkError,
	isCustomError,
	isNativeChunkLoadError,
	isNativeNetworkError,
	isZodError,
	isZodOriginalError,
} from './error-type.guard';

export const ERROR_TYPE = {
	Resource: 'resource',
	Network: 'network',
	ApiResponse: 'api-response',
	Unknown: 'unknown',
	Code: 'code',
	Validation: 'validation',
	Custom: 'custom',
};

const errorByStatusCodeMap = {
	400: BadRequestError,
	401: UnauthorizedError,
	403: ForbiddenError,
	404: NotFoundError,
	422: OneCError,
	429: TooManyRequestsError,
	500: InternalServerError,
};

type ApiException = typeof errorByStatusCodeMap[keyof typeof errorByStatusCodeMap];
type ErrorConstructor =
	| typeof ChunkLoadError
	| typeof TypeError
	| typeof RangeError
	| typeof ReferenceError
	| typeof ZodError
	| typeof NetworkError
	| typeof UnknownError
	| ApiException;

export const createErrorFactory = (
	error: unknown,
): {
	code: number | null;
	type: typeof ERROR_TYPE[keyof typeof ERROR_TYPE];
	exception: InstanceType<ErrorConstructor>;
} => {
	if (isCustomError(error)) {
		return {
			code: isApiError(error) ? error.code : null,
			type: ERROR_TYPE.Custom,
			exception: error,
		};
	}
	if (isZodOriginalError(error)) {
		return {
			code: null,
			type: ERROR_TYPE.Validation,
			exception: new ZodError({ cause: error.errors, message: error.message }),
		};
	}

	if (isZodError(error)) {
		return {
			code: null,
			type: ERROR_TYPE.Validation,
			exception: error,
		};
	}

	if (isNativeChunkLoadError(error)) {
		return {
			code: null,
			type: ERROR_TYPE.Resource,
			exception: new ChunkLoadError(),
		};
	}

	if (isNativeNetworkError(error)) {
		return {
			code: null,
			type: 'network',
			exception: new NetworkError(),
		};
	}

	if (isAxiosError(error)) {
		const { message: originalMessage = 'No message in error' } = error.response?.data || {};
		const code = error.response?.status;

		if (isAxiosNetworkError(error)) {
			return {
				code: null,
				type: ERROR_TYPE.Network,
				exception: new NetworkError(),
			};
		}

		let message: string = originalMessage;

		if (isAxiosErrorWithMessagesArray(error)) {
			message = Object.entries(error.response.data.errors ?? {})
				.map(([, reason]) => reason)
				.join('\n');
		}

		const Exception: ApiException = errorByStatusCodeMap[code];

		if (Exception) {
			const resolvedMessage = code === 403 ? error.response.data?.error || 'Немає доступа до ресурса' : message;

			return {
				code,
				type: ERROR_TYPE.ApiResponse,
				exception: new Exception({ message: resolvedMessage, cause: error.cause }),
			};
		}

		return {
			code: null,
			type: ERROR_TYPE.Unknown,
			exception: new UnknownError(),
		};
	}

	const CodeRelatedError = getCodeRelatedError(error);

	if (CodeRelatedError) {
		const err = error as Error;

		return {
			code: null,
			type: ERROR_TYPE.Code,
			// @ts-ignore
			exception: new CodeRelatedError({ message: err.message, cause: err.cause }),
		};
	}

	return {
		code: null,
		type: ERROR_TYPE.Unknown,
		exception: new UnknownError(),
	};
};

export const transformError = (err: ReturnType<typeof createErrorFactory>) => {
	const { exception, code } = err;

	return {
		message: exception.message,
		error: exception,
		code,
	};
};
