import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import React from 'react';
import { Controller } from 'react-hook-form';
import { toFixed } from 'utils/shared';

import styles from './styles.module.css';

interface ServiceDependantInputFieldProps {
	suborderIndex: number;
	disabled?: boolean;
	entityId: string;
}

const ServiceDependantInputField: React.FC<ServiceDependantInputFieldProps> = ({ entityId, suborderIndex, disabled }) => {
	const { control, setDirtyValue, getValues } = useOrderOperationMethods();

	const priceFieldName = `suborders.${suborderIndex}.data.services.${entityId}.price` as const;

	return (
		<Controller
			name={priceFieldName}
			control={control}
			render={({ field }) => {
				return (
					<input
						disabled={disabled}
						readOnly={disabled}
						className={styles.inputField}
						type="number"
						value={field.value}
						onChange={(e) => {
							const allSuborders = getValues('suborders') ?? [];

							if (suborderIndex > 0) {
								const price = Number(e.currentTarget.value);

								const sumInAllEntityOccurrences = allSuborders.reduce((acc, suborder, index) => {
									if (index === 0) return acc;
									if (index !== suborderIndex) {
										return (acc +=
											Number(suborder.data.services[entityId]?.amount) * Number(suborder.data.services[entityId]?.price));
									}

									acc += Number(price) * Number(suborder.data.services[entityId]?.amount);
									return acc;
								}, 0);

								// @ts-ignore
								setDirtyValue(`suborders.${0}.data.services.${entityId}.sum`, toFixed(sumInAllEntityOccurrences));

								allSuborders.forEach((_, index) => {
									// @ts-ignore
									setDirtyValue(`suborders.${index}.data.services.${entityId}.price`, toFixed(price));
								});
								field.onChange(e);
							} else {
								const price = Number(e.currentTarget.value);
								const amount = Number(getValues(`suborders.${suborderIndex}.data.services.${entityId}.amount`));

								// @ts-ignore
								setDirtyValue(`suborders.${suborderIndex}.data.services.${entityId}.sum`, toFixed(price * Number(amount)));

								allSuborders.forEach((_, index) => {
									// @ts-ignore
									setDirtyValue(`suborders.${index}.data.services.${entityId}.price`, toFixed(price));
								});
								field.onChange(e);
							}
						}}
						onBlur={() => {
							const numberedValue = Number(field.value);
							if (!numberedValue) {
								field.onChange('0.00');
							} else {
								field.onChange(toFixed(numberedValue));
							}
						}}
					/>
				);
			}}
		/>
	);
};

export default ServiceDependantInputField;
