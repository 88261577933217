import { extractProductCategoryIds, extractStockIdsWhereProductIsAvailable } from 'lib/utils';
import type { OfflineCatalogueProductModel, OfflineOrderModel, OfflineProductCategoryNode } from 'models/offline';
import type { Order, OrderPreview } from 'models/order';
import type { CatalogueProduct } from 'models/product/catalogue-product';
import type { ProductCategory } from 'models/product/category';
import { stripTimeFromDate } from 'utils/dates';
import { uuid } from 'utils/shared';

export const transformOrderToPreviewOrder = (order: Order): OrderPreview => {
	return {
		id: order.id,
		sum: order.sum,
		number: order.number,
		appNumber: order.appNumber,
		status: order.status,
		paymentStatus: order.paymentStatus,
		logisticsStatus: order.logisticsStatus,
		type: order.type,
		stock: order?.stock?.title ?? '',
		parentId: order.parentId ?? null,
		note: order.note ?? '',
		contract: order?.contract?.title ?? '',
		date: order.date,
		createdAt: order.createdAt,
		responsible: order.responsible?.name ?? '',
		// @ts-ignore
		client: order?.client?.organizationName || order?.client?.name || '',
		...(order && 'isOfflineCreated' in order && { isOfflineCreated: order.isOfflineCreated }),
	};
};

export const transformOfflineOrderModelToOrder = (offlineOrderModel: OfflineOrderModel) => {
	const order: Order = {};

	for (const property in offlineOrderModel) {
		if (!Object.hasOwn(offlineOrderModel, property)) continue;

		if (property.startsWith('ik')) continue;

		order[property] = offlineOrderModel[property];
	}

	return order;
};

export const transformOrderToOfflineOrderModel = (order: Order) => {
	const offlineOrderModel: OfflineOrderModel = {
		...order,
		ikClientName: order.client?.name ?? '',
		ikClientId: order?.client?.id ?? '',
		ikContract: order?.contract?.title ?? '',
		ikDate: stripTimeFromDate(new Date(order.date)),
		ikManagerId: String(order.responsible?.id ?? ''),
		ikNumber: order.number,
		ikPaymentDate: order.payments.length > 0 ? order.payments.map((payment) => stripTimeFromDate(new Date(payment.date))) : [null],
		ikPaymentStatus: order.paymentStatus,
		ikRealizationDate: order.realizations.map((realization) => stripTimeFromDate(new Date(realization.date))),
		ikStatus: order.status,
		ikStock: order.stock.id,
		ikSum: Number(order.sum),
		ikSortByStock: order.stock?.title ?? '',
	};

	return offlineOrderModel;
};

export const duplicateOrder = (order: Order, appNumber: string): OfflineOrderModel => {
	const today = new Date();

	return {
		...order,
		id: uuid(),
		subOrders: [],
		parentId: null,
		appNumber: appNumber,
		number: appNumber,
		note: '',
		status: 'calculation' as const,
		logisticsStatus: 'Очікує загрузку',
		paymentStatus: 'no_payment',
		access: 'full',
		isReserved: false,
		isClosed: false,
		isPaid: false,
		createdAt: today,
		date: today,
		payments: [],
		realizations: [],
		ikClientId: order.client?.id,
		ikManagerId: order.responsible?.['1c_uuid'] ?? '',
		ikStatus: 'calculation',
		ikPaymentStatus: 'no_payment',
		ikSum: order.sum,
		ikStock: order.stock.id ?? '',
		ikDate: today,
		ikPaymentDate: null,
		ikRealizationDate: null,
		ikContract: order.contract?.id ?? '',
		ikNumber: appNumber,
		// @ts-ignore
		ikClientName: order.client?.organizationName || order.client?.name || '',
		ikSortByStock: order.stock?.title ?? '',
		isOfflineCreated: true,
	};
};

export const transformProductToOfflineProductModel = (product: CatalogueProduct): OfflineCatalogueProductModel => {
	return {
		...product,
		title: product.title.trim(),
		ikBrand: product.brand.id,
		ikParentIds: extractProductCategoryIds(product.group as OfflineProductCategoryNode),
		ikStock: extractStockIdsWhereProductIsAvailable(product),
	};
};
export const transformOfflineProductModelToProduct = (model: OfflineCatalogueProductModel): CatalogueProduct => {
	const product: CatalogueProduct = {};

	for (const property in model) {
		if (!Object.hasOwn(model, property)) continue;

		if (property.startsWith('ik')) continue;

		product[property] = model[property];
	}

	return product;
};

export const transformCategoryNodeToProductCategory = (node: OfflineProductCategoryNode | null): ProductCategory | null => {
	if (!node) {
		return null;
	}
	return {
		id: node.id,
		title: node.title,
		isLast: isLastCategory(node),
		// @ts-ignore
		parent: node.parent ? transformCategoryNodeToProductCategory(node?.parent) : null,
	};
};

export const findCategoryWithParents = (id: string | null, nodes: OfflineProductCategoryNode[]): ProductCategory[] => {
	if (!id) {
		return nodes.map(transformCategoryNodeToProductCategory).filter(Boolean) as ProductCategory[];
	}

	for (const node of nodes) {
		if (node.id === id) {
			return [transformCategoryNodeToProductCategory(node)];
		}

		const result = findCategoryWithParents(id, node.subCategories);

		if (result) {
			return [transformCategoryNodeToProductCategory(node), ...result];
		}
	}

	return [];
};

function isLastCategory(node: OfflineProductCategoryNode): number {
	return node.subCategories.length === 0 ? 1 : 0;
}
