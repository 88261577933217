import Counter from 'components/Counter';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import React from 'react';
import { Controller } from 'react-hook-form';
import { formatInputValue } from 'utils/inputs';
import { toFixed } from 'utils/shared';

import styles from './styles.module.css';
import type { ServiceCounterProps } from './types';

const ServiceCounter: React.FC<ServiceCounterProps> = ({ suborderIndex, entityId, disabled }) => {
	const { control, setDirtyValue, getValues } = useOrderOperationMethods();

	const updateValues = (amount: number | string) => {
		const allSuborders = getValues('suborders') ?? [];
		const sumInAllEntityOccurrences = allSuborders.reduce((acc, suborder, index) => {
			if (index === 0) return acc;
			if (index !== suborderIndex) {
				return (acc += Number(suborder.data.services[entityId]?.amount) * Number(suborder.data.services[entityId]?.price));
			}
			acc += Number(amount) * Number(suborder.data.services[entityId]?.price);
			return acc;
		}, 0);

		const newSum = toFixed(sumInAllEntityOccurrences);
		const newAmount = toFixed(amount);

		// @ts-ignore
		setDirtyValue(`suborders.${0}.data.services.${entityId}.sum`, newSum);
		// @ts-ignore
		setDirtyValue(`suborders.${0}.data.services.${entityId}.amount`, newAmount);
	};

	const valueFormatter = formatInputValue({ isInt: true });

	return (
		<Controller
			name={`suborders.${suborderIndex}.data.services.${entityId}.amount`}
			control={control}
			render={({ field }) => {
				const numberedValue = Number(field.value);

				return (
					<Counter
						min={1}
						value={isNaN(numberedValue) ? 1 : numberedValue}
						onQuantityChange={(newValue) => {
							const val = valueFormatter(newValue);
							updateValues(val);
							field.onChange(val);
						}}
						onBlur={() => {
							if (!numberedValue) {
								updateValues(0);
								field.onChange('0');
							} else {
								field.onChange(valueFormatter(numberedValue));
							}
						}}
						disabled={disabled}
						className={styles.servicesCounter}
					/>
				);
			}}
		/>
	);
};

export default ServiceCounter;
